import { Card, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import isToday from 'date-fns/isToday'
import isWithinInterval from 'date-fns/isWithinInterval'
import isYesterday from 'date-fns/isYesterday'
import parseISO from 'date-fns/parseISO'
import subMinutes from 'date-fns/subMinutes'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { NudgeType } from '../../../graphql/generated/autogenerated'
import { ExtendedNudgeType } from '../../../hooks/useGenerateNotification/pseudo/psuedoNotifications'
import useGenerateNotification, {
    Notification
} from '../../../hooks/useGenerateNotification/useGenerateNotification'
import useTimeLabel from '../../../hooks/useTimeLabel/useTimeLabel'
import { ReactNull, ReactNullValue } from '../../../utils/nulls'
import NotificationItem from '../NotificationItem/NotificationItem'

// Helper type for grouped notifications
type GroupedNotifications = {
    [key: string]: { [key: string]: Notification[] }
}

// Check if the notification timestamp is within the last 5 minutes
const isJustNow = (timestamp: string): boolean => {
    const now = new Date()
    const notificationTime = parseISO(timestamp)
    return isWithinInterval(notificationTime, {
        start: subMinutes(now, 5),
        end: now
    })
}

// Format the date label based on whether it is today, yesterday, or an earlier date
const formatDateLabel = (date: Date, locale: string): string => {
    if (isToday(date)) {
        return 'Today'
    } else if (isYesterday(date)) {
        return 'Yesterday'
    }
    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }).format(date)
}

// Group notifications by date and then by time
const groupNotificationsByDateAndTime = (
    notifications: Notification[],
    locale: string
): GroupedNotifications => {
    const validNudgeTypes = [
        ...(NudgeType ? Object.values(NudgeType) : []),
        ...(ExtendedNudgeType ? Object.values(ExtendedNudgeType) : [])
    ]

    // Remove Announcement Nudge from List
    notifications = notifications.filter(
        (notification) =>
            notification.nudgeType !== NudgeType.WebNudgesLaunchAnnouncement
    )

    const filteredNotifications = notifications.filter((notification) =>
        validNudgeTypes.includes(notification.nudgeType)
    )

    return filteredNotifications.reduce((groups, notification) => {
        if (!notification?.targetTimestamp) {
            if (!groups['No Date']) {
                groups['No Date'] = {}
            }
            if (!groups['No Date']['']) {
                groups['No Date'][''] = []
            }
            groups['No Date'][''].push(notification)
            return groups
        }

        const parsedDate = parseISO(notification.targetTimestamp)
        const dateLabel = formatDateLabel(parsedDate, locale)
        if (!groups[dateLabel]) {
            groups[dateLabel] = {}
        }

        let timeLabel: string

        if (isJustNow(notification.targetTimestamp)) {
            timeLabel = 'Just now'
        } else {
            timeLabel = useTimeLabel()(notification.targetTimestamp)
        }

        if (!groups[dateLabel][timeLabel]) {
            groups[dateLabel][timeLabel] = []
        }

        groups[dateLabel][timeLabel].push(notification)
        return groups
    }, {} as GroupedNotifications)
}

// Props for NotificationList component
export interface NotificationListProps {
    notifications: Notification[]
    isLarge?: boolean
    onHandleClose: () => void
    onSetClicked: (notification: Notification) => void
    onSetOpenPermissionSnackbar?: (allowed: boolean) => void
}

const NotificationList: React.FC<NotificationListProps> = ({
    notifications = [],
    isLarge = false,
    onHandleClose,
    onSetClicked,
    onSetOpenPermissionSnackbar
}): ReactElement => {
    const { palette } = useTheme()
    const { locale } = useIntl()

    const announcementNudge = useMemo(() => {
        return notifications.find(
            (notification) =>
                notification.nudgeType === NudgeType.WebNudgesLaunchAnnouncement
        )
    }, [notifications])

    const { generateNotification } = useGenerateNotification()
    const groupedNotifications = groupNotificationsByDateAndTime(
        notifications,
        locale
    )

    const renderDateHeader = useCallback(
        (dateLabel: string): ReactElement => (
            <CoreTypography
                component="div"
                variant={isLarge ? 'h5' : 'body2'}
                color={isLarge ? 'text.primary' : 'text.secondary'}
                px={2}
                pb={1}
            >
                {dateLabel}
            </CoreTypography>
        ),
        [isLarge]
    )

    const renderTimeHeader = useCallback(
        (timeLabel: string): ReactElement => (
            <CoreTypography
                component="div"
                variant={isLarge ? 'h6' : 'body2'}
                color={isLarge ? 'text.primary' : 'text.secondary'}
                px={2}
                pb={1}
            >
                {timeLabel}
            </CoreTypography>
        ),
        [isLarge]
    )

    const renderNotification = useCallback(
        (
            index: number,
            notification: Notification
        ): ReactElement | ReactNull => {
            const bgSx = {
                bgcolor: !notification?.read ? palette.grey[100] : undefined
            }
            const { webNotification } = generateNotification(notification)
            if (!webNotification) return ReactNullValue

            return (
                <Stack key={index} direction="column" gap={1}>
                    <Stack py={1} px={2} sx={bgSx}>
                        <Card
                            elevation={0}
                            variant={isLarge ? 'outlined' : undefined}
                            sx={bgSx}
                        >
                            <Stack p={isLarge ? 3 : 0}>
                                <NotificationItem
                                    notification={notification}
                                    webNotification={webNotification}
                                    handleClose={onHandleClose}
                                    setClicked={onSetClicked}
                                    setOpenPermissionSnackbar={
                                        onSetOpenPermissionSnackbar
                                    }
                                />
                            </Stack>
                        </Card>
                    </Stack>
                </Stack>
            )
        },
        [
            isLarge,
            palette.grey,
            onSetClicked,
            onHandleClose,
            generateNotification,
            onSetOpenPermissionSnackbar
        ]
    )

    return (
        <Stack data-testid="notification-list" gap={1}>
            {/* Render Announcement Nudge First */}
            {!announcementNudge?.expired &&
                !announcementNudge?.clicked &&
                announcementNudge &&
                renderNotification(-1, announcementNudge as Notification)}
            {/* Render Pseudo Notifications */}
            {groupedNotifications['No Date'] &&
                Object.keys(groupedNotifications['No Date'])
                    .flatMap(
                        (timeLabel) =>
                            groupedNotifications['No Date'][timeLabel]
                    )
                    .filter(
                        (notification) =>
                            !notification.expired && !notification.clicked
                    )
                    .map((notification, index) =>
                        renderNotification(index, notification)
                    )}

            {/* Render Date-Grouped and Time-Grouped Notifications */}
            <Stack data-testid="date-time-group" gap={1}>
                {Object.keys(groupedNotifications)
                    .filter((dateLabel) => dateLabel !== 'No Date')
                    .sort((a, b) => {
                        if (a === 'Today') return -1
                        if (b === 'Today') return 1
                        if (a === 'Yesterday') return -1
                        if (b === 'Yesterday') return 1
                        return parseISO(b).getTime() - parseISO(a).getTime()
                    })
                    .map((dateLabel) => {
                        const dateGroup = groupedNotifications[dateLabel]

                        // Check if any valid notifications exist for this dateLabel
                        const hasValidNotifications = Object.values(dateGroup)
                            .flat()
                            .some(
                                (notification) =>
                                    !notification.expired &&
                                    !notification.clicked
                            )

                        if (!hasValidNotifications) return

                        return (
                            <Stack
                                data-testid="date-group"
                                key={dateLabel}
                                gap={1}
                            >
                                {renderDateHeader(dateLabel)}
                                {Object.keys(dateGroup)
                                    .sort((a, b) => {
                                        const firstNotificationA =
                                            dateGroup[a][0]
                                        const firstNotificationB =
                                            dateGroup[b][0]
                                        const timeA = parseISO(
                                            firstNotificationA.targetTimestamp
                                        ).getTime()
                                        const timeB = parseISO(
                                            firstNotificationB.targetTimestamp
                                        ).getTime()
                                        return timeB - timeA // Sort by latest time first
                                    })
                                    .map((timeLabel) => {
                                        const timeGroup = dateGroup[timeLabel]

                                        // Check if any valid notifications exist for this timeLabel
                                        const hasValidTimeNotifications =
                                            timeGroup.some(
                                                (notification) =>
                                                    !notification.expired &&
                                                    !notification.clicked
                                            )

                                        if (!hasValidTimeNotifications) return

                                        return (
                                            <Stack
                                                key={timeLabel}
                                                data-testid="time-group"
                                            >
                                                {renderTimeHeader(timeLabel)}
                                                {timeGroup
                                                    .filter(
                                                        (notification) =>
                                                            !notification.expired &&
                                                            !notification.clicked
                                                    )
                                                    .map(
                                                        (notification, index) =>
                                                            renderNotification(
                                                                index,
                                                                notification
                                                            )
                                                    )}
                                            </Stack>
                                        )
                                    })}
                            </Stack>
                        )
                    })}
            </Stack>
        </Stack>
    )
}

export default NotificationList
